<template>
  <div class = "min">
    <el-card v-loading="taskdetailsloading">
      <div class = "flexRow">
        <div class = "statebox-now" v-if="taskdetailsmsg.State==1">进行中</div>
        <div class = "statebox-now statebox-stop" v-if="taskdetailsmsg.State==2">已结束</div>
        <div class = "taskname">
          {{taskdetailsmsg.Name}}
        </div>
      </div>
      <div class = "grayfont" style="margin-top:30px">推送规则：
        <span v-if="taskdetailsmsg.PushRuleType==1">定时仅推送一次</span>
        <span v-if="taskdetailsmsg.PushRuleType==2">周期循环</span>
        <span v-if="taskdetailsmsg.PushRuleType==3">客户行为触发</span>
      </div>
      <div class = "flexRow" style="margin-top:10px">
        <div class = "grayfont">推送时间：
          <span>{{taskdetailsmsg.PushTime}}</span></div>
        <div class = "grayfont" style="margin-left:50px">最近一次推送时间：
          <span>{{taskdetailsmsg.LastPushTime}}</span></div>
      </div>
      <div class = "grayfont flexRow" style="margin-top:10px">推送渠道：
        <div class = "flexRow" style="margin:0px 30px 0px 8px" v-for="(v,i) in taskdetailsmsg.MessageTypeList" :key="i">
          <img v-if="v==1" :src="ImgUrl+'/image/1ce92b46faf189922aa9da7fa4d6646.png'" style="width:20px;"  alt="">
          <img v-else-if="v==2" :src="ImgUrl+'/image/9b762be18c6ddd5266bfbd544617731.png'" style="width:20px;"  alt="">
          <img v-else-if="v==3" :src="ImgUrl+'/image/automarketData3.png'" style="width:20px;"  alt="">
          <div v-if="v==1" class = "grayfont" style="margin:0px 0px 0px 5px">企业微信</div>
          <div v-else-if="v==2" class = "grayfont" style="margin:0px 0px 0px 5px">短信</div>
          <div v-else-if="v==3" class = "grayfont" style="margin:0px 0px 0px 5px">小程序订阅消息</div>
        </div>
      </div>
      <div class = "grayfont" style="margin-top:10px">转化跟踪： {{taskdetailsmsg.Transform}}</div>
    </el-card>

    <el-card style="margin-top:10px" v-loading="datadetailsloading">
      <div class = "taskname" style="margin:0px">数据概况</div>
      <div style="margin:20px 0px">
        <el-radio-group v-model="surveyswitch" @change="getdatadetailsmsg(1)" v-if="taskdetailsmsg.MessageTypeList&&taskdetailsmsg.MessageTypeList.length>1">
          <el-radio-button v-for="v in taskdetailsmsg.MessageTypeList" :key="v" :label="v">
            <span v-if="v==1">企业微信</span>
            <span v-if="v==2">短信</span>
            <span v-if="v==3">小程序订阅消息</span>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class = "flexbetween">
        <div class = "flexbetween" style="flex-wrap:wrap;flex:1">
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.CoverMemberCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计覆盖人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="符合推送条件的人数，人数单日去重，多日累加；包含通知失败人数" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.NoticeSuccessCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计通知成功人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="通过对应渠道，通知成功的人数，人数单日去重，多日累加；企业微信渠道需要员工在企业微信内点击发送，系统才可更新通知成功人数" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.NoticeFailCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计通知失败人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="" placement="top">
                <div slot="content">
                  通过对应渠道，通知失败的人数，人数单日去重，多日累加；短信渠道，客户在覆盖人群中，但是客户没有手机号，客户通知失败；企业微信渠道，客户在覆盖人群中，但是客户未添加任一员工企业微信，客户通知失败等
                  <el-button type="text" style="magin:0px;padding:0px" v-if="surveyswitch==1" @click="jump">点击查看通知详情</el-button>
                </div>
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.VisitMemberCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">商城访问人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且进入商城小程序访问的人数，人数单日去重，多日累加" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.SendCouponSuccessCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">优惠券发放成功人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="优惠券发放或领取成功的人数，人数单日去重，多日累加" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.SendCouponFailCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">优惠券发放失败人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="优惠券发放或领取失败的人数，人数单日去重，多日累加；若优惠券限制领取次数，客户达到次数，此时优惠券发放或领取失败等" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.SendWheelSurfSuccessCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">抽奖赠送成功人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="抽奖机会赠送成功的人数，人数单日去重，多日累加" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.SendWheelSurfFailCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">抽奖赠送失败人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="抽奖机会赠送失败的人数，人数单日去重，多日累加；若抽奖活动已结束，抽奖机会赠送失败" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class = "line"></div>
        <div class = "flexbetween" style="flex-wrap:wrap;flex:1">
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.OrderMemberCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计下单人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且进入商城小程序访问，并且成功提交订单的人数，人数单日去重，多日累加" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.OrderCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计下单笔数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且进入商城小程序访问的客户，提交成功的订单数量，1人提交多笔计多笔" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.OrderMoney}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计下单金额</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且进入商城小程序访问的客户，提交成功的订单金额，金额包含运费，不剔除退款或订单关闭" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.PayOrderMemberCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计支付人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且进入商城小程序访问，并且成功支付订单的人数，人数单日去重，多日累加" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.PayOrderCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计支付笔数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且进入商城小程序访问的客户，支付成功的订单数量，1人支付多笔计多笔" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.PayOrderMoney}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">累计支付金额</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且进入商城小程序访问的客户，支付成功的订单金额，金额包含运费，不剔除退款或订单关闭" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{datadetailsmsg.CompleteGoalMemberCount}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">目标完成人数</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道通知成功，并且完成转化目标的人数，人数单日去重，多日累加" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
          <div class = "databox flexCol">
            <div class = "datanumber">{{getefficiency(datadetailsmsg.CompleteGoalMemberCount,datadetailsmsg.NoticeSuccessCount)}}</div>
            <div class = "flexRow" style="margin-top:10px">
              <div class = "datakey">目标完成率</div>
              <el-tooltip popper-class="itemtooltip" effect="dark" content="对应渠道目标完成人数/对应渠道通知成功人数*100%" placement="top">
                <i class = "el-icon-question" style="margin-left:5px"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card style="margin-top:10px">
      <div class = "taskname" style="margin:0px">营销转化漏斗</div>
      <div style="margin:20px 0px">
        <el-radio-group v-model="funnelswitch" @change="getdatadetailsmsg(2)" v-if="taskdetailsmsg.MessageTypeList&&taskdetailsmsg.MessageTypeList.length>1">
          <el-radio-button v-for="v in taskdetailsmsg.MessageTypeList" :key="v" :label="v">
            <span v-if="v==1">企业微信</span>
            <span v-if="v==2">短信</span>
            <span v-if="v==3">小程序订阅消息</span>
          </el-radio-button>
        </el-radio-group>
      </div>
      <!-- be08d23e8b925c318e54d60f153966f -->
      <div class = "imgflex">
        <div class = "flexCol" style="height:390px;justify-content: space-between;align-items:flex-start;">
          <div class = "leftrectangular" style="background:rgba(93, 112, 146,.2)">
            <div>
              <div style="font-weight:bold">{{datadetailsmsg.CoverMemberCount}}</div>
              <div class = "grayfont" style="margin-top:5px">累计覆盖人数</div>
            </div>
          </div>
          <div class = "leftrectangular" style="background:rgba(91, 143, 249,.2)">
            <div>
              <div style="font-weight:bold">{{datadetailsmsg.NoticeSuccessCount}}</div>
              <div class = "grayfont" style="margin-top:5px">累计通知成功人数</div>
            </div>
          </div>
          <div class = "leftrectangular" style="background:rgba(66, 178, 150,.2)">
            <div>
              <div style="font-weight:bold">{{datadetailsmsg.VisitMemberCount}}</div>
              <div class = "grayfont" style="margin-top:5px">商城访问人数</div>
            </div>
          </div>
          <div class = "leftrectangular" style="background:rgba(240, 206, 70,.2)">
            <div>
              <div style="font-weight:bold">{{datadetailsmsg.OrderMemberCount}}</div>
              <div class = "grayfont" style="margin-top:5px">累计下单人数</div>
            </div>
          </div>
          <div class = "leftrectangular" style="background:rgba(232, 104, 74,.2)">
            <div>
              <div style="font-weight:bold">{{datadetailsmsg.PayOrderMemberCount}}</div>
              <div class = "grayfont" style="margin-top:5px">累计支付人数</div>
            </div>
          </div>
        </div>
        <div class = "imgposition">
          <div class = "imgposition-fontbox">
            <div class = "percent" style="margin-left:50px">
              <div class= "grayfont">转化率</div>
              <div class= "grayfont">{{getefficiency(datadetailsmsg.NoticeSuccessCount,datadetailsmsg.CoverMemberCount)}}</div>
            </div>
            <div class = "percent" style="margin:53px 0px 0px 30px">
              <div class= "grayfont">转化率</div>
              <div class= "grayfont">{{getefficiency(datadetailsmsg.VisitMemberCount,datadetailsmsg.NoticeSuccessCount)}}</div>
            </div>
            <div class = "flexRow" style="margin:38px 0px 0px 15px">
              <div class = "percent">
                <div class= "grayfont">转化率</div>
                <div class= "grayfont">{{getefficiency(datadetailsmsg.OrderMemberCount,datadetailsmsg.VisitMemberCount)}}</div>
              </div>
              <div class = "percent" style="margin-left:60px">
                <div class= "grayfont">转化率</div>
                <!-- 最右边那个 -->
                <div class= "grayfont">{{getefficiency(datadetailsmsg.PayOrderMemberCount,datadetailsmsg.NoticeSuccessCount)}}</div>
              </div>
            </div>
            <div class = "percent" style="margin:32px 0px 0px 0px">
              <div class= "grayfont">转化率</div>
              <div class= "grayfont">{{getefficiency(datadetailsmsg.PayOrderMemberCount,datadetailsmsg.NoticeSuccessCount)}}</div>
            </div>
          </div>
          <img :src="ImgUrl+'/image/be08d23e8b925c318e54d60f153966f.png'" class="imgposition-img" alt="">
        </div>
      </div>
    </el-card>

    <el-card style="margin-top:10px">
      <div class = "taskname" style="margin:0px">营销转化详情</div>
      <div style="margin:20px 0px">
        <el-radio-group v-model="detailsswitch" @change="gettablelist" v-if="taskdetailsmsg.MessageTypeList&&taskdetailsmsg.MessageTypeList.length>1">
          <el-radio-button v-for="v in taskdetailsmsg.MessageTypeList" :key="v" :label="v">
            <span v-if="v==1">企业微信</span>
            <span v-if="v==2">短信</span>
            <span v-if="v==3">小程序订阅消息</span>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="StatisDate" label="日期" width="180"></el-table-column>
          <el-table-column prop="CoverMemberCount" label="覆盖人数" width="100"></el-table-column>
          <el-table-column prop="NoticeSuccessCount" label="通知成功" width="100"></el-table-column>
          <el-table-column prop="NoticeFailCount" label="通知失败" width="100"></el-table-column>
          <el-table-column prop="SendCouponSuccessCount" label="优惠券发放成功" width="130"></el-table-column>
          <el-table-column prop="SendCouponFailCount" label="优惠券发放失败" width="130"></el-table-column>
          <el-table-column prop="SendWheelSurfSuccessCount" label="抽奖赠送成功" width="130"></el-table-column>
          <el-table-column prop="SendWheelSurfFailCount" label="抽奖赠送失败" width="130"></el-table-column>
          <el-table-column prop="VisitMemberCount" label="商城访问人数" width="130"></el-table-column>
          <el-table-column prop="OrderMemberCount" label="下单人数" width="100"></el-table-column>
          <el-table-column prop="OrderCount" label="下单笔数" width="100"></el-table-column>
          <el-table-column prop="OrderMoney" label="下单金额" width="100"></el-table-column>
          <el-table-column prop="PayOrderMemberCount" label="支付人数" width="100"></el-table-column>
          <el-table-column prop="PayOrderCount" label="支付笔数" width="100"></el-table-column>
          <el-table-column prop="PayOrderMoney" label="支付金额" width="100"></el-table-column>
          <el-table-column prop="CompleteGoalMemberCount" label="目标完成人数" width="130"></el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  datastatisdatastatisautomarketingtaskconfigdata,
  datastatisdatastatisautomarketingtaskconfigoverview,
  datastatisdatastatisautomarketingtaskconfigdetail
}from "@/api/sv3.0.0"
import config from "@/config"
export default {
  data () {
    return {
      ImgUrl:config.IMG_BASE,
      state:null,
      surveyswitch:1,
      funnelswitch:1,
      detailsswitch:1,
      tablelist:[],
      tableloading:false,
      currentPage:1,
      sizepage:20,
      total:null,

      taskdetailsloading:false,
      taskdetailsmsg:{},
      datadetailsloading:false,
      datadetailsmsg:{},
    }
  },
  created () {
    this.gettaskdetailsmsg()
  },
  methods: {
    jump(){
      this.$router.push({
        name:'Massrecord',
        params:{
          Id:this.$route.query.Id
        }
      })
    },
    getefficiency(a,b){
      if(a==0&&b==0){
         return (100)+'%'
      }else if(b==0){
         return '100.00%'
      }else if(a==0){
         return '0%'
      }else{
        return (a/b*100).toFixed(2)+'%'
      }
    },
    handleSizeChange(e){
      this.currentPage=1
      this.sizepage=e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage=e
      this.gettablelist()
    },
    async gettablelist(){
      this.tableloading=true
      try{
        let data = {
          AutoMartetingTaskConfigId:this.$route.query.Id,
          MessageType:this.detailsswitch,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage,
        }
        let result = await datastatisdatastatisautomarketingtaskconfigdetail(data)
        if(result.IsSuccess){
          this.tablelist = result.Result.Results.map((v)=>{
            v.StatisDate = v.StatisDate.substring(0,10)
            return v
          })
          this.total = result.Result.Total
        }
      }finally{
        this.tableloading=false
      }
    },
    async gettaskdetailsmsg(){
      this.taskdetailsloading = true
      try{
        let data = {
          AutoMartetingTaskConfigId:this.$route.query.Id,
        }
        let result = await datastatisdatastatisautomarketingtaskconfigdata(data)
        if(result.IsSuccess){
          this.taskdetailsmsg = result.Result
          this.taskdetailsmsg.MessageTypeList.sort((a,b)=>{
            return a-b
          })
          if(this.taskdetailsmsg.MessageTypeList&&this.taskdetailsmsg.MessageTypeList.length){
            this.surveyswitch = this.taskdetailsmsg.MessageTypeList[0]
            this.funnelswitch = this.taskdetailsmsg.MessageTypeList[0]
            this.detailsswitch = this.taskdetailsmsg.MessageTypeList[0]
          }
          
          this.getdatadetailsmsg()
          this.gettablelist()
          // console.log(this.taskdetailsmsg,'this.taskdetailsmsg')
        }
      }finally{
        this.taskdetailsloading = false
      }
    },
    async getdatadetailsmsg(e){
      this.datadetailsloading = true
      try{
        let MessageType = this.surveyswitch
        if(e==1){
          MessageType = this.surveyswitch
        }else if(e==2){
          MessageType = this.funnelswitch
        }
        let data = {
          AutoMartetingTaskConfigId:this.$route.query.Id,
          MessageType:MessageType
        }
        let result = await datastatisdatastatisautomarketingtaskconfigoverview(data)
        if(result.IsSuccess){
          this.datadetailsmsg = result.Result
        }
      }finally{
        this.datadetailsloading = false
      }
    },

  }
}
</script>

<style lang = "less" scope>
  .flexRow{
    display: flex;
    align-items: center;
  }
  .flexbetween{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .statebox-now{
    padding: 5px 10px;
    background: #F0F9EB;
    opacity: 1;
    border: 1px solid #C2E7B0;
    font-size: 12px;
    color: #67C23A;
  }
  .statebox-stop{
    border: 1px solid #EBEEF5;
    background: #F8F8F9;
    color: #606266;
  }
  .taskname{
    font-size: 16px;
    font-weight: bold;
    color: #303133;
    margin-left: 20px;
  }
  .grayfont{
    font-size: 14px;
    color: #606266;
    white-space: nowrap;
    line-height: 1.5;
  }
  .databox{
    padding:20px 50px;
    width: 33%;
    /* align-items: flex-start; */
    /* border:1px solid black; */
    .datanumber{
      font-size: 16px;
      color: #303133;
      font-weight: bold;
      white-space: nowrap;
    }
    .datakey{
      font-size: 14px;
      color: #606266;
      white-space: nowrap;
    }
  }
  .databox:last-child{
    margin-right: auto;
  }
  .databox:nth-child(3n+1){
    padding:20px 50px 20px 0px;
    /* margin-left:0px; */
    /* border:1px solid black; */
    align-items:flex-start;
    .datanumber{
      margin-left: 40px;
    }
  }
  .databox:nth-child(3n){
    padding:20px 0px 20px 50px;
    /* margin-left:0px; */
    align-items:flex-end;
    .datanumber{
      margin-right: 60px;
    }
  }
  .line{
    width: 0px;
    height: 135px;
    margin:0px 50px;
    opacity: 1;
    border: 1px solid #DCDFE6;
  }
  .flexCol{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
</style>
<style lang = "less" scope>
  .imgflex{
    position: relative;
  }
  .imgposition{
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    .imgposition-img{
      position: absolute;
      right: 80px;
      top: 0px;
    }
    .imgposition-fontbox{
      position: absolute;
      right: 0px;
      top: 45px;
      bottom: 4px;
      display: flex;
      flex-direction: column;
      width: 200px;
      .percent{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
      }
    }
  }
  .leftrectangular{
    height:70px;
    /* border:1px solid black; */
    width:75%;
    /* color: rgb(240, 206, 71) */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left:30px
  }
.itemtooltip{
  max-width: 400px;
}
.min{
  min-width:1152px;
  overflow:hidden;overflow-x:auto
}
</style>
<style>
</style>